import React from "react"
import { Paragraph, Link } from "evergreen-ui"
import StartButton from "../components/StartButton"

export default ({ location }) => {
  return (
    <>
      <Paragraph size={500} marginTop="default">
        Vážený servisní partnere,
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        děkujeme za váš zájem stát se partnerským servisem společnosti Goodyear
        v rámci internetového obchodu. Partnerské servisy nabízejí své služby
        konečným zákazníkům, kteří využívají elektronický obchod náležející
        společnosti Goodyear. V rámci spolupráce obdržíte za každou transakci
        poplatek za montáž a manipulační příplatek za pneumatiku. Seznamte se
        prosím s přiloženým{" "}
        <Link
          href="/manuals/goodyear.cz_manual2021.pdf"
          target="_blank"
          size={500}
        >
          manuálem
        </Link>{" "}
        pro více informací o programu.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Pro zaregistrování se do programu dokončete všechny kroky online
        registrace.
      </Paragraph>

      <Paragraph size={500} marginTop="default">
        Po skončení procesu registrace obdržíte Vy i společnost Goodyear email
        obsahující všechny údaje z registrace. Poté společnost Goodyear Vaši
        registraci ověří a po ověření Vám zašle oznámení, že jste byli
        aktivováni jako partnerský servis společnosti Goodyear v rámci
        internetového obchodu.
      </Paragraph>

      <Paragraph size={500} marginTop="default" marginBottom="default">
        Ještě jednou děkujeme za váš zájem o program společnosti Goodyear.
        Doufáme, že Vás už brzy přivítáme jako partnerský servis.
      </Paragraph>
      <StartButton search={location.search} />
    </>
  )
}
